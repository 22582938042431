import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Layout, Menu } from "antd";

const Header = ({ location }) => (
  <div></div>
);

export default withRouter(Header);

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import App from "./App";

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens)
}


ReactDOM.render(
  <React.StrictMode>
      <Router>
        <App>
          <Switch>
            <Route key="index" exact path="/" >
                   <DashboardPage />
            </Route>
          </Switch>
        </App>
      </Router>
   </React.StrictMode>,
  document.getElementById("root")
);

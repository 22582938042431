import React from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table } from 'antd';
import { Chart, Axis, Tooltip, Geom, PieChart, Legend, Line, Coordinate } from 'bizcharts';
import moment from "moment";

const numberFormatter = item => new Intl.NumberFormat('en-US').format(item);
const colors = ["#7DB3FF", "#49457B", "#FF7C78"];

let search = window.location.search;
let params = new URLSearchParams(search);
  

let startDate = params.get('start');
let endDate = params.get('end');

let start = moment(startDate, "YYYY-MM-DD");
let end = moment(endDate, "YYYY-MM-DD");

//Difference in number of days
const days = moment.duration(end.diff(start)).asDays();
console.log(days);
localStorage.setItem('days', JSON.stringify(days));


function sumProperty(arr, type) {

  return arr.reduce((total, obj) => {
    
    if (typeof obj[type] === 'string') {
      return total + Number(obj[type]);
    }

    return total + obj[type];
  }, 0);
}

const stackedBarChartData = (resultSet) => {
  
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: moment(resultSet.axisValuesString(xValues, ', ')).format("MMM"),
        color: resultSet.axisValuesString(yValues, ', ').substring(0, 4)+' '+resultSet.axisValuesString(yValues, ', ').substring(51).replace(", Units.amount", ""),
        measure: m && Number.parseFloat(m),
        year: resultSet.axisValuesString(yValues, ', ').substring(0, 4)
      }))
    )
    .reduce((a, b) => a.concat(b), []);
    console.log(data);
  return data;
};

const stackedChartData = (resultSet) => {
  
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};
const stackedChartDataFactory = (resultSet) => {
  
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: (resultSet.axisValuesString(xValues, ', ') === '(F)actory' ? (m && Number.parseFloat(m*6)) : (m && Number.parseFloat(m))),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
    console.log('factorydata',data)

  return data;
};
const stackedChartDataHorizontal = (resultSet) => {
  
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data.reverse();
};

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 8,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis name="x" />
        <Axis name="measure" />
        <Tooltip
          crosshairs={{
            type: 'y',
          }}
        />
        <Geom type="line" position="x*measure" size={2} color="color" />
      </Chart>
    );
  },
  bar: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}
        />
        <Axis name="measure"  label= {{ formatter: val => `${numberFormatter(val)}` }}/>
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Geom type="interval" position="x*measure" color="color" />
      </Chart>
    );
  },
  barFactory: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedChartDataFactory(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}
        />
        <Axis name="measure"  label= {{ formatter: val => `${numberFormatter(val)}` }}/>
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Geom type="interval" position="x*measure" color="color" />
      </Chart>
    );
  },
  barStacked: ({ resultSet, colors }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedBarChartData(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}
        />
        <Axis name="measure"  label= {{ formatter: val => `${numberFormatter(val)}` }} />
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Geom type="interval" position={`x*measure`}
          color={[
              "color",
              function(color) {
                if(color.includes('APAC')) return '#FF5768';
                if(color.includes('EMEA')) return '#8DD7BF';
                if(color.includes('AMERICAS')) return '#00A5E3';
                if(color.includes('Market Place App & Social Media')) return '#FF5768';
                if(color.includes('Website')) return '#8DD7BF';
                if(color.includes('Domain')) return '#00A5E3';
                return '#6C88C4';
              }
            ]}
            style={{
            stroke: '#fff',
            lineWidth: 0.3,
          }}
          adjust={[
            {
              type: 'dodge',
              dodgeBy: 'year',
              marginRatio: 0.25
            },
            {
              type: 'stack'
            },
          ]}
          
          />
          
      </Chart>
    );
  },
  barHorizontal: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 10,
          },
        }}
        autoFit
        height={400}
        data={stackedChartDataHorizontal(resultSet)}
        forceFit
      >
        <Axis
         name="x"
         label = {{
            //rotate:  30,   
            autoRotate: true,
            Offset: 30
          }}

        />
        <Axis name="measure" label= {{ formatter: val => `${numberFormatter(val)}` }}  />
        <Legend selectedmode="multiple" visible={false} />
        <Tooltip />
        <Coordinate reflect="measure" />
        <Coordinate transpose />
        <Geom type="interval" position="x*measure" color="color" />
      </Chart>
    );
  },
  
  area: ({ resultSet }) => {
    return (
      <Chart
        scale={{
          x: {
            tickCount: 8,
          },
        }}
        autoFit
        height={400}
        data={stackedChartData(resultSet)}
        forceFit
      >
        <Axis name="x" />
        <Axis name="measure" />
        <Tooltip
          crosshairs={{
            type: 'y',
          }}
        />
        <Geom type="area" position="x*measure" size={2} color="color" />
      </Chart>
    );
  },
  pie: ({ resultSet }) => {
    if(resultSet.series()[0] === undefined ) return null;
    return (
      <PieChart
        placeholder="No data"
        data={resultSet.chartPivot()}
        radius={0.6}
        angleField={resultSet.series()[0].key}
        colorField="x"
        label={{
          visible: true,
          offset: 15,
          formatter: val => `${numberFormatter(val[resultSet.series()[0].key])}`,
        }}
        legend={{
          position: 'bottom',
          labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 10
                    }
                }
        }}
        forceFit
      />
    );
  },
  pieColors: ({ resultSet }) => {
    if(resultSet.series()[0] === undefined ) return null;
    return (
      <PieChart
        data={resultSet.chartPivot()}
        radius={0.6}
        angleField={resultSet.series()[0].key}
        colorField="x"
        color={(d)=>{
          console.log(d)
            if(d.x==='APAC') return '#FF5768';
            if(d.x==='EMEA') return '#8DD7BF';
            if(d.x==='AMERICAS') return '#00A5E3';
            return '#6C88C4';
        }}
        label={{
          visible: true,
          offset: 15,
          formatter: val => `${numberFormatter(val[resultSet.series()[0].key])}`,
        }}
        legend={{
          position: 'bottom',
          labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 10
                    }
                }
        }}
        forceFit
      />
    );
  },
  piePercentage: ({ resultSet }) => {
    return (
    
      <PieChart
        data={resultSet.chartPivot()}
        radius={0.6}
        angleField={resultSet.series()[0].key}
        colorField="x"
        color={(d)=>{
          console.log(d)
            if(d.x==='APAC') return '#FF5768';
            if(d.x==='EMEA') return '#8DD7BF';
            if(d.x==='AMERICAS') return '#00A5E3';
            return '#6C88C4';
        }}
        label={{
          visible: true,
          offset: 20,
          formatter: val => `${(val[resultSet.series()[0].key]/(sumProperty(resultSet.chartPivot(), resultSet.series()[0].key) )*100).toFixed(2)}%`,
        }}
        legend={{
          position: 'bottom',
        }}
        forceFit
      /> 
    );
  },
  pieSide3: ({ resultSet }) => {
    return (
      <Row>
        <Col span={18}>
          <PieChart height={400}
            data={resultSet.chartPivot()}
            radius={0.8}
            angleField={resultSet.series()[0].key}
            colorField="x"
            color={(d)=>{
              console.log(d)
                if(d.x==='APAC') return '#FF5768';
                if(d.x==='EMEA') return '#8DD7BF';
                if(d.x==='AMERICAS') return '#00A5E3';
                return '#6C88C4';
            }}
            label={{
              visible: true,
              offset: 20,
            }}
            legend={{
              position: 'top',
            }}
          />
        </Col>
        <Col span={6}>
          <h3>Side</h3>
        </Col>
      </Row>
    );
  },
  list: ({ resultSet }) => {
    console.log('list',resultSet.loadResponse.results.data)
    const data = resultSet.loadResponse.results[0].data;
    return (

      <Row
      type="flex"
      justify="center"
      align="middle"
      style={{
        height: '100%',
      }}
    >
      <Col>
        {data.map((item, index) => {
          // Extract product name and total amount
          const productName = item['Products.value'];
          const totalAmount = item['Units.amount'];

          // Round the total amount to the nearest integer
          const roundedAmount = Math.round(totalAmount);

          // Format the rounded amount as currency (assuming USD)
          const formattedAmount = roundedAmount.toLocaleString('en-US');

          return (
            <div key={index} style={{ marginBottom: '10px' }}>
              <span style={{
          fontSize: '18px',
        }}><strong>{productName}</strong>: {formattedAmount}</span>
            </div>
          );
        })}
      </Col>
    </Row>
    );
  },
  number: ({ resultSet }) => {
    return (

      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            
           <Statistic style={{
          fontWeight: 'bold',
        }} value={~~(resultSet.totalRow()[s.key] ?? 0)} />
            
            
          ))}
        </Col>
      </Row>
    );
  },
  numberFactory: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            
           <Statistic style={{
          fontWeight: 'bold',
        }} value={~~(resultSet.totalRow()[s.key]*6)} />
            
            
          ))}
        </Col>
      </Row>
    );
  },

  numberYear: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[0].data[0] !== undefined && resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{marginTop: '-10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
                  {~~Number(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key]))/~~Number(resultSet.loadResponse.results[1].data[0][s.key])*100)))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span>
                  <Statistic style={{
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }} value={~~(resultSet.loadResponse.results[0].data[0][s.key] ?? 0)} />
                 
                   
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberYearGrouped: ({ resultSet }) => {
    if(resultSet.loadResponse.results[0].data[0] === undefined) return null;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{marginTop: '-10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
          

                  {~~Number(Math.floor((((resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + current['CountriesGrouped.distinct'], 0)-resultSet.loadResponses[1].data.reduce((accumulator, current) => accumulator + current['CountriesGrouped.distinct'], 0)))/~~Number(resultSet.loadResponses[1].data.reduce((accumulator, current) => accumulator + current['CountriesGrouped.distinct'], 0))*100)))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span>
                  <Statistic style={{
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }} value={~~(resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + current['CountriesGrouped.distinct'], 0))} />
                 
                   
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberYearTotal: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{marginTop: '-10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
                  {~~Number(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key]))/~~Number(resultSet.loadResponse.results[1].data[0][s.key])*100)))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span>
                  <Statistic style={{
                    fontWeight: 'bold',
                    textAlign: 'center'
                  }} value={~~(resultSet.loadResponse.results[0].data[0][s.key] ?? 0) } />
                 
                   
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberMulti: ({ resultSet }) => {
    console.log('restitution',resultSet)
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          <Statistic style={{
          fontWeight: 'bold',
          }} value={~~(resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + current['RestitutionOffsets.amount'], 0)+resultSet.loadResponses[1].data.reduce((accumulator, current) => accumulator + current['RestitutionWired.amount'], 0))} />
        </Col>
      </Row>
    );
  },
  numberTotal: ({ resultSet }) => {
    console.log('total',resultSet)
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          <Statistic style={{
          fontWeight: 'bold',
          float: 'left'
          }} value={~~(resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + current['Totals.amount'], 0)+resultSet.loadResponses[1].data.reduce((accumulator, current) => accumulator + current['Totals.amount']*6, 0))} />
          <small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {(resultSet.loadResponses[2].data.reduce((accumulator, current) => accumulator + current['Totals.amount'], 0)+resultSet.loadResponses[3].data.reduce((accumulator, current) => accumulator + current['Totals.amount']*6, 0)).toLocaleString('en-US')}</small>
        </Col>
      </Row>
    );
  },
  numberTotalSingleImpact: ({ resultSet }) => {
    console.log('total',resultSet)
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          <Statistic style={{
          fontWeight: 'bold',
          float: 'left'
          }} value={~~(resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + current['Totals.amount'], 0)+resultSet.loadResponses[1].data.reduce((accumulator, current) => accumulator + current['Totals.amount']*6, 0))} />
        </Col>
      </Row>
    );
  },
  numberTotalSingle: ({ resultSet }) => {
    console.log('total',resultSet)
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          <Statistic style={{
          fontWeight: 'bold',
          float: 'left'
          }} value={~~(resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + (current['RestitutionTotals.amount'] ? current['RestitutionTotals.amount'] : current['Totals.amount']), 0)+resultSet.loadResponses[1].data.reduce((accumulator, current) => accumulator + current['Totals.amount'], 0)+resultSet.loadResponses[2].data.reduce((accumulator, current) => accumulator + current['Totals.amount']*6, 0)+resultSet.loadResponses[3].data.reduce((accumulator, current) => accumulator + current['Totals.amount'], 0))} />
        </Col>
      </Row>
    );
  },
  numberTotalScore: ({ resultSet }) => {
    console.log('totalScore',resultSet)
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          <Statistic style={{
          fontWeight: 'bold',
          float: 'left'
          }} value={~~(((resultSet.loadResponses[0].data.reduce((accumulator, current) => accumulator + (current['Tcs.amount']/current['Mps.amount']),0)/resultSet.loadResponses[0].data.length).toFixed(2)*100) ?? 0) + '%'} />
          
        </Col>
      </Row>
    );
  },

  numberTotalDivision: ({ resultSet }) => {
    console.log('total',resultSet)
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        
        <Col>
        <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={~~(resultSet.loadResponse.results[0].data[0]['Totals.amount']/resultSet.loadResponse.results[0].data[0]['Units.amount'] ?? 0) } />
                   {<small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {~~(resultSet.loadResponse.results[1]?.data[0]['Totals.amount']/resultSet.loadResponse.results[1]?.data[0]['Units.amount'])}</small> }
                  </div>
         
        </Col>
      </Row>
    );
  },
  numberTotalDivisionIssues: ({ resultSet }) => {
    console.log('total',resultSet)
    const amount = resultSet.loadResponse.results[1].data[0]['Totals.amount'];
    const count = resultSet.loadResponse.results[1].data[0]['Issues.count'];
    const result = amount / count;
    const roundedResult = Math.round(result);
    const formattedResult = roundedResult.toLocaleString('en-US');
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        
        <Col>
        <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={~~(resultSet.loadResponse.results[0].data[0]['Totals.amount']/resultSet.loadResponse.results[0].data[0]['Issues.count'] ?? 0)
                      
                     } />
                   {<small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {formattedResult}</small> }
                  </div>
         
        </Col>
      </Row>
    );
  },
  
  numberYearDay: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              <div>     
                  <span  className="increment ant-btn ant-btn-round ant-btn-sm" style={{marginTop: '-10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
                  {Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span>
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={resultSet.loadResponse.results[0].data[0][s.key] ?? 0} />
                    <small style={{float:'left', paddingLeft:'5px',paddingTop:'10px', fontSize: '14px'}}>{(resultSet.loadResponse.results[0].data[0][s.key]/365).toFixed(2)}/day</small>
                  </div>
              </div>
            ))}
        </Col>
      </Row>
    );
  },
  numberDay: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
           <Statistic style={{
          fontWeight: 'bold',
        }} value={~~(resultSet.loadResponse.results[0].data[0][s.key]/localStorage.getItem('days')).toFixed(2)} />
          ))}
        </Col>
      </Row>
    );
  },
  numberPrevYearPercent: ({ resultSet }) => {
    if(resultSet.loadResponse.results[0].data[0] === undefined) return null;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>    
              <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{display: 'block', margin: 'auto', marginTop: '-30px', marginBottom: '10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? "red" : "green"}} >
                  {~~Number(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key]))/~~Number(resultSet.loadResponse.results[1].data[0][s.key])*100)))}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0][s.key]-resultSet.loadResponse.results[1].data[0][s.key])/resultSet.loadResponse.results[1].data[0][s.key])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span> 
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={resultSet.loadResponse.results[0].data[0][s.key] ?? 0} />
                   {resultSet.loadResponse.results[1].data[0][s.key] &&  <small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {resultSet.loadResponse.results[1].data[0][s.key].toLocaleString('en-US')}</small> }
                  </div>
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberPercentDivision: ({ resultSet }) => {
    if(resultSet.loadResponse.results[0].data[0] === undefined) return null;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>    
              <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{display: 'block', margin: 'auto', marginTop: '-30px', marginBottom: '10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0]['Totals.amount']/resultSet.loadResponse.results[0].data[0]['Units.amount']))/~~Number(resultSet.loadResponse.results[1].data[0]['Totals.amount']/resultSet.loadResponse.results[1].data[0]['Units.amount'])*100))) === -1 ? "red" : "green"}} >
                  {~~Number(Math.floor((((resultSet.loadResponse.results[0].data[0]['Totals.amount']/resultSet.loadResponse.results[0].data[0]['Units.amount']))/~~Number(resultSet.loadResponse.results[1].data[0]['Totals.amount']/resultSet.loadResponse.results[1].data[0]['Units.amount'])*100)))-100}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0]['Totals.amount']/resultSet.loadResponse.results[0].data[0]['Units.amount']))/~~Number(resultSet.loadResponse.results[1].data[0]['Totals.amount']/resultSet.loadResponse.results[1].data[0]['Units.amount'])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span> 
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={Math.floor(resultSet.loadResponse.results[0].data[0]['Totals.amount']/resultSet.loadResponse.results[0].data[0]['Units.amount']) ?? 0} />
                  </div>
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberPrevYearPercentDivision: ({ resultSet }) => {
    if(resultSet.loadResponse.results[0].data[0] === undefined) return null;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>    
              <span className="increment ant-btn ant-btn-round ant-btn-sm" style={{display: 'block', margin: 'auto', marginTop: '-30px', marginBottom: '10px',color: Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0]['Units.amount']/resultSet.loadResponse.results[0].data[0]['Issues.count']))/~~Number(resultSet.loadResponse.results[1].data[0]['Units.amount']/resultSet.loadResponse.results[1].data[0]['Issues.count'])*100))) === -1 ? "red" : "green"}} >
                  {~~Number(Math.floor((((resultSet.loadResponse.results[0].data[0]['Units.amount']/resultSet.loadResponse.results[0].data[0]['Issues.count']))/~~Number(resultSet.loadResponse.results[1].data[0]['Units.amount']/resultSet.loadResponse.results[1].data[0]['Issues.count'])*100)))-100}% previous year
                  {Math.sign(Math.floor((((resultSet.loadResponse.results[0].data[0]['Units.amount']/resultSet.loadResponse.results[0].data[0]['Issues.amount']))/~~Number(resultSet.loadResponse.results[1].data[0]['Units.amount']/resultSet.loadResponse.results[1].data[0]['Issues.count'])*100))) === -1 ? (
                    <span>&nbsp;&#8675;</span>
                  ) : (
                    <span>&nbsp;&#8673;</span>
                  )}
                  </span> 
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={Math.floor(resultSet.loadResponse.results[0].data[0]['Units.amount']/resultSet.loadResponse.results[0].data[0]['Issues.count']) ?? 0} />
                    {resultSet.loadResponse.results[1].data[0]['Units.amount'] &&  <small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {~~Number(Math.floor(resultSet.loadResponse.results[1].data[0]['Units.amount']/resultSet.loadResponse.results[1].data[0]['Issues.count']))}</small> }

                  </div>
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberPrevYear: ({ resultSet }) => {
    if(resultSet.loadResponse.results[0].data[0] === undefined) return null;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={resultSet.loadResponse.results[0].data[0][s.key] ?? 0} />
                   {resultSet.loadResponse.results[1].data[0][s.key] &&  <small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {resultSet.loadResponse.results[1].data[0][s.key].toLocaleString('en-US')}</small> }
                  </div>
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  numberPrevYearFactory: ({ resultSet }) => {
    if(resultSet.loadResponse.results[0].data[0] === undefined) return null;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
            {resultSet.seriesNames().slice(0,1).map((s,index) => (
              resultSet.loadResponse.results[1].data[0] !== undefined && (
              <div>     
                  <div style={{display:'flex', justifyContent: 'center'}}>
                    <Statistic style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      float: 'left'
                    }} value={(resultSet.loadResponse.results[0].data[0][s.key]*6) ?? 0} />
                   {resultSet.loadResponse.results[1].data[0][s.key]*6 &&  <small style={{float:'left', paddingLeft:'5px',paddingTop:'5px', fontSize: '18px'}}>/ {(resultSet.loadResponse.results[1].data[0][s.key]*6).toLocaleString('en-US')}</small> }
                  </div>
              </div>
              )
            ))}
        </Col>
      </Row>
    );
  },
  table: ({ resultSet, pivotConfig }) => {
    return (
      <Table
        pagination={false}
        columns={resultSet.tableColumns(pivotConfig)}
        dataSource={resultSet.tablePivot(pivotConfig)}
      />
    );
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = (Component) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} />) ||
  (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState, title }) => {
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component && renderChart(component)(renderProps);
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
import React from "react";
import { Card } from "antd";

const DashboardItem = ({ children, title }) => (
  <Card
    //title={title}
    style={{
      height: "100%",
      width: "100%",
    }}
    className={"antd-pro-pages-dashboard-lite-index-boxNonTitle"}
  >
    <h1
      style={{
        padding: "0px 0px 0px",
      }}
    >
      {title}
    </h1>
    {children}
  </Card>
);

export default DashboardItem;

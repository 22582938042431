import "./body.css";
import "antd/dist/antd.css";
import React from "react";
import "@ant-design/compatible";
import { Layout } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import Header from "./components/Header";
import WebSocketTransport from "@cubejs-client/ws-transport";

const API_URL = "https://stats.uabp.eu";
//const API_URL = "http://localhost:3001";
const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDE5MjE0NDIsImV4cCI6MTY0MjAwNzg0Mn0.X8rVmvgkAN2t3y5be5ekcmqueHxAlvbmhd53qzDZJ5A";

/*const cubejsApi = cubejs({
  transport: new WebSocketTransport({
    authorization: CUBEJS_TOKEN,
    apiUrl: API_URL.replace("https", "wss"),
  }),
});*/

const cubejsApi = cubejs(CUBEJS_TOKEN,{
  apiUrl: 'https://stats.uabp.eu/cubejs-api/v1',
});

const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: "100%",
    }}
  >
    <Header />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <AppLayout>{children}</AppLayout>
  </CubeProvider>
);

export default App;
